import React, { useState } from 'react';

interface UnsubscribeFormProps {
    error?: string|null;
    cancelLabel: string;
    numberLabel?: string;
    unsubscribeLabel: string;
    onCancel?: () => void;
    onUnsubscribe?: (number: string) => void;
}

const UnsubscribeForm: React.FC<UnsubscribeFormProps> = ({ cancelLabel, error = null, numberLabel, unsubscribeLabel, onCancel, onUnsubscribe }) => {
    const [number, setNumber] = useState<string>('');

    const handleUnsubscribe = () => {
        if (onUnsubscribe && number !== '') {
            onUnsubscribe(number);
        }
    }

    const formatPhoneNumber = (value: any) => {
        if (!value) return value;

        const phoneNumber = value.replace(/[^\d]/g, "");

        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3)}`;
        }

        return `${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3, 6)} - ${phoneNumber.slice(6, 10)}`;
    };

    const handleInput = (value: any) => {
        setNumber(formatPhoneNumber(value));
    }

    return (
        <div className="formBody">
            {numberLabel && 
                <>
                    <label className="formLabel">{numberLabel}:</label>&nbsp;
                </>
            }
            <div className="formInputsContainer">
                <div className="formInputBlock">
                    <input
                        className={`formInput ${error && 'errorFormInput'}`}
                        type='type'
                        name="unsubscribeNumber"
                        onChange={e => handleInput(e.currentTarget.value)}
                        value={number}
                        placeholder="eg, 416-555-1234"
                        required={true}
                        autoFocus
                    />
                    {error && <div className="formErrorMessage">{error}</div>}
                </div>
                <div className="formButtons">
                    <button
                        className="formButton"
                        type="submit"
                        value="Submit"
                        disabled={(number === '')}
                        onClick={handleUnsubscribe}
                        >{unsubscribeLabel}</button>
                    <button
                        className="formButton formButton-cancel"
                        type="button"
                        value="cancel"
                        onClick={onCancel}
                        >{cancelLabel}</button>
                </div>
            </div>
        </div>
    );
}

export default UnsubscribeForm;