import React, { useState, Dispatch, SetStateAction } from "react";
import UnsubscribeForm from "./UnsubscribeForm";
import { CPM_CHANNEL, CPM_LEVEL } from "../App";
import ArrowRight from "../svgs/ArrowRight";
import ArrowLeft from "../svgs/ArrowLeft";
import { TranslationProps } from "../App.d";

interface CpmFormProps {
  cancelLabel: string;
  numberLabel?: string;
  unsubscribeLabel: string;
  carrierName: string | null;
  setCpmLevel: Dispatch<SetStateAction<string | null>>;
  setCpmChannel: Dispatch<SetStateAction<string | null>>;
  setFormError: Dispatch<SetStateAction<string | null>>;
  onUnsubscribe: (number: string) => void;
  translations: TranslationProps;
}

const CpmForm: React.FC<CpmFormProps> = ({
  setCpmLevel,
  setCpmChannel,
  setFormError,
  carrierName,
  cancelLabel,
  numberLabel,
  unsubscribeLabel,
  onUnsubscribe,
  translations,
}) => {
  const [formPage, setFormPage] = useState<number>(1);
  const [radioChecked, setRadioChecked] = useState<string | null>(null);
  const [showUnsubscribe, setShowUnsubscribe] = useState<boolean>(false);

  const allCarrierLine: string =
    translations.casl_all_carrier || "All :carrier_name:";

  const handleForwardClick = (level: string) => {
    setCpmLevel(level);
    setFormPage(formPage + 1);
  };

  const handleRadioSelect = (channel: string) => {
    setRadioChecked(channel);
    setCpmChannel(channel);
    setShowUnsubscribe(true);
  };

  const handleCancel = () => {
    setFormPage(1);
    setCpmChannel(null);
    setCpmLevel(null);
    setRadioChecked(null);
    setShowUnsubscribe(false);
    setFormError(null);
  };

  const renderCpmForm = () => {
    switch (formPage) {
      case 1:
        return (
          // TODO: Replace arrows with SVGs
          <div className="formButtons cpm">
            {`${translations.casl_click_below}:`}
            <button
              className="formButton cpm"
              onClick={() => handleForwardClick(CPM_LEVEL.DEALER)}
            >
              <div className="buttonLeft">{translations.casl_all_dealer}</div>
              <div className="buttonRight">
                {" "}
                <ArrowRight />{" "}
              </div>
            </button>
            <button
              className="formButton cpm"
              onClick={() => handleForwardClick(CPM_LEVEL.CARRIER)}
            >
              <div className="buttonLeft">
                {carrierName &&
                  allCarrierLine.replace(":carrier_name:", carrierName)}
              </div>
              <div className="buttonRight">
                {" "}
                <ArrowRight />{" "}
              </div>
            </button>
          </div>
        );
      case 2:
        return (
          <div className="cpmRadio">
            <div className="cpmBack">
              <button onClick={() => handleCancel()}>
                <span>
                  <ArrowLeft />
                  &nbsp;{translations.back}
                </span>
              </button>
            </div>
            <p>{`${translations.casl_cpm_channel}:`}</p>
            <div className="cpm-radio">
              <div
                onClick={() => handleRadioSelect(CPM_CHANNEL.SMS)}
                className={`radio-group ${
                  radioChecked === CPM_CHANNEL.SMS && "radio-group-selected"
                }`}
              >
                <input
                  checked={radioChecked === CPM_CHANNEL.SMS}
                  onChange={() => handleRadioSelect(CPM_CHANNEL.SMS)}
                  type="radio"
                  name="CPM_CHANNEL"
                  value={CPM_CHANNEL.SMS}
                />
                <span
                  onClick={() => handleRadioSelect(CPM_CHANNEL.SMS)}
                  id={CPM_CHANNEL.SMS}
                  className="checkmark"
                />
                <label htmlFor={CPM_CHANNEL.SMS}>{translations.sms}</label>
              </div>
              <div
                onClick={() => handleRadioSelect(CPM_CHANNEL.CALLING)}
                className={`radio-group ${
                  radioChecked === CPM_CHANNEL.CALLING && "radio-group-selected"
                }`}
              >
                <input
                  checked={radioChecked === CPM_CHANNEL.CALLING}
                  type="radio"
                  name="CPM_CHANNEL"
                  onChange={() => handleRadioSelect(CPM_CHANNEL.CALLING)}
                  value={CPM_CHANNEL.CALLING}
                />
                <span
                  onClick={() => handleRadioSelect(CPM_CHANNEL.CALLING)}
                  id={CPM_CHANNEL.CALLING}
                  className="checkmark"
                />
                <label htmlFor={CPM_CHANNEL.CALLING}>
                  {translations.casl_calling}
                </label>
              </div>
              <div
                onClick={() => handleRadioSelect(CPM_CHANNEL.ALL)}
                className={`radio-group ${
                  radioChecked === CPM_CHANNEL.ALL && "radio-group-selected"
                }`}
              >
                <input
                  checked={radioChecked === CPM_CHANNEL.ALL}
                  onChange={() => handleRadioSelect(CPM_CHANNEL.ALL)}
                  type="radio"
                  name="CPM_CHANNEL"
                  value={CPM_CHANNEL.ALL}
                />
                <span
                  onClick={() => handleRadioSelect(CPM_CHANNEL.ALL)}
                  id={CPM_CHANNEL.ALL}
                  className="checkmark"
                />
                <label htmlFor={CPM_CHANNEL.ALL}>
                  {translations.casl_all_channels}
                </label>
              </div>
            </div>
            {showUnsubscribe && (
              <UnsubscribeForm
                cancelLabel={cancelLabel}
                numberLabel={numberLabel}
                unsubscribeLabel={unsubscribeLabel}
                onCancel={() => handleCancel()}
                onUnsubscribe={(number) => onUnsubscribe(number)}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderCpmForm()}</div>;
};

export default CpmForm;
